// Copyright 2022 mitchelleglon
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
import React from "react";
import Projects from "../components/Projects";

function ProjectsPage() {
  return (
    <div className="page-content-container">
      <h2 className="portfolio-title">PROJECTS</h2>
      {/* <p className="portfolio-into">Here is a sample of my latest work:</p> */}
      <Projects />
    </div>
  );
}

export default ProjectsPage;
