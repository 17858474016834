// Copyright 2022 mitchelleglon
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
import React from "react";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import EmailIcon from "@mui/icons-material/Email";
import GitHubIcon from "@mui/icons-material/GitHub";

function Footer() {
  return (
    <div className="footer-container">
      <div className="contacts-container">
        <div className="email-contact">
          <a href="mailto:mitcheglon@hotmail.co.uk">
            <EmailIcon style={{ fontSize: "3rem" }} />
          </a>
        </div>
        <div className="linkedin-contact">
          <a href="https://www.linkedin.com/in/mitchell-eglon-2a325bb1/">
            <LinkedInIcon style={{ fontSize: "3rem" }} />
          </a>
        </div>
        <div className="github-contact">
          <a href="https://github.com/MitchEglon94">
            <GitHubIcon style={{ fontSize: "3rem" }} />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
