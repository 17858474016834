import React from "react";
import { Link } from "react-router-dom";

import logo from "../assets/ME-1.png";

function Header() {
  return (
    <div className="navbar-container">
      <div className="nav-item home-link">
        <Link
          to="/"
          style={{
            fontSize: "1rem",
            textDecoration: "none",
            color: "rgb(58, 58, 58)",
          }}
        >
          <img src={logo} alt="logo" width="75px" />
        </Link>
      </div>
      <div className="nav-item">
        <Link
          to="about"
          style={{
            fontSize: "1rem",
            textDecoration: "none",
            color: "rgb(58, 58, 58)",
          }}
        >
          About
        </Link>
      </div>
      <div className="nav-item">
        <Link
          to="projects"
          style={{
            fontSize: "1rem",
            textDecoration: "none",
            color: "rgb(58, 58, 58)",
          }}
        >
          Projects
        </Link>
      </div>
      <div className="nav-item">
        <Link
          to="skills"
          style={{
            fontSize: "1rem",
            textDecoration: "none",
            color: "rgb(58, 58, 58)",
          }}
        >
          Skills
        </Link>
      </div>
    </div>
  );
}

export default Header;
