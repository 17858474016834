// Copyright 2022 mitchelleglon
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
import React from "react";
import { skillsInfo } from "../appData";

console.log(skillsInfo);

function Skills() {
  return (
    <div className="skills-card-container">
      {skillsInfo.map((item) => (
        <div className="skills-card" key={item.title}>
          <img src={item.img} alt="project preview" />
          <div className="skills-card-text">
            <h3>{item.title}</h3>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Skills;
