import shop from "./assets/shop-app-preview.jpg";
import ticketMaster from "./assets/ticketmaster-preview.jpg";
import rocket from "./assets/rocket-preview.jpg";
import tipCalc from "./assets/tip-calc-preview.jpg";
import weather from "./assets/weather-app-preview.jpg";
import mole from "./assets/whackamole-preview.jpg";
import movie from "./assets/movie-app.jpg";
import nba from "./assets/nba-preview.png";

export const projectInfo = [
  {
    title: "NBA mobile app",
    img: nba,
    description:
      "An app I built to enabling users to check when the next games are, see the team rosters and current league tables collecting all information from the sportsdata.io API. Built using Next.js 13, tailwind CSS and deployed using Vercel.",
    tech: "Next.js 13 | Tailwind CSS | Vercel",
    liveLink: "https://nba-app-repo-f29bwe6an-mitcheglon94.vercel.app/",
    githubLink: "https://github.com/MitchEglon94/nba-app-repo",
  },
  {
    title: "Movie Matcher",
    img: movie,
    description:
      "My first fullstack MERN app; designed to remove the stress of agreeing on a movie/tv show with a friend or partner. Create an account, browse the dashboard of movies and shows and simply 'like' any that catch your eye. If you navigate to the user search page, then you can search any user and see which liked items you both have in common. Github Repo is private currently, please request if you would like access",
    tech: "React | Redux Toolkit | mongoDB | Express | NodeJS | Mongoose | Heroku | Netlify",
    liveLink: "https://movie-matcher-social.netlify.app",
    githubLink: "https://github.com/MitchEglon94",
  },
  {
    title: "One Stop Shop",
    img: shop,
    description:
      "A mock up React Redux Toolkit shop app I made using the Fake Store API to gather random shop items. Built focussed on displaying an understand of Redux Toolkit.",
    tech: "React | Redux Toolkit",
    liveLink: "https://react-shop-app-mitch.netlify.app",
    githubLink: "https://github.com/MitchEglon94/react-shop-app",
  },
  {
    title: "Tip Calculator",
    img: tipCalc,
    description:
      "Javascript Tip Calculator built from a Figma design spec with mobile and desktop views - from Frontend Mentor.",
    tech: "Javascript | HTML | CSS | Figma",
    liveLink: "https://tip-calc-fem-demo.netlify.app",
    githubLink: "https://github.com/MitchEglon94/Tip-Calculator",
  },
  {
    title: "Weather Dashboard",
    img: weather,
    description:
      "A location based weather dashboard I built with Javascript, HTML and CSS, utilising Javascripts Geolocation API and the Open weather map API",
    tech: "Javascript | HTML | CSS",
    liveLink: "https://weather-dashboard-mitch.netlify.app/",
    githubLink: "https://github.com/MitchEglon94/Weather-app-dashboard",
  },
  {
    title: "Whack-a-mole",
    img: mole,
    description:
      "Classic arcade game Whack-a-mole recreated using HTML, CSS and Javascript. Built to practise DOM manipulation and collaboration as we worked in a team of 3. The source code is commented to highlight the parts that each of us contributed.",
    tech: "Javascript | HTML | CSS | Adobe Illustrator",
    liveLink: "https://whack-a-mole-proj.netlify.app/",
    githubLink: "https://github.com/MitchEglon94/whack-a-mole",
  },
  {
    title: "Ticketmaster Clone",
    img: ticketMaster,
    description:
      "A clone I made of the Ticketmaster home page, using only CSS and HTML. I built with a mobile first approach at 375px screen width, and added resposnive breakpoints at 768px and 1024px.",
    tech: "HTML | CSS",
    liveLink: "https://ticketmaster-clone-mitch.netlify.app/",
    githubLink: "https://github.com/MitchEglon94/ticketmaster-clone-html-css",
  },
  {
    title: "Rocket launch SVG design",
    img: rocket,
    description:
      "My winning submission into an SVG animation challenge, made by utilising CSS keyframes (desktop only)",
    tech: "HTML | CSS | Adobe Illustrator",
    liveLink: "https://rocket-man-svg.netlify.app/",
    githubLink: "",
  },
];

export const skillsInfo = [
  {
    title: "Javascript",
    img: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/javascript/javascript-original.svg",
  },
  {
    title: "React",
    img: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg",
  },
  {
    title: "HTML5",
    img: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/html5/html5-original-wordmark.svg",
  },
  {
    title: "CSS3",
    img: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/css3/css3-original-wordmark.svg",
  },
  {
    title: "SASS",
    img: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/sass/sass-original.svg",
  },
  {
    title: "Bootstrap",
    img: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/bootstrap/bootstrap-plain-wordmark.svg",
  },
  {
    title: "GraphQL",
    img: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/graphql/graphql-plain-wordmark.svg",
  },
  {
    title: "Redux",
    img: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/redux/redux-original.svg",
  },
  {
    title: "MongoDB",
    img: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/mongodb/mongodb-plain-wordmark.svg",
  },
  {
    title: "Express",
    img: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/express/express-original.svg",
  },
  {
    title: "PostgreSQL",
    img: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/postgresql/postgresql-plain-wordmark.svg",
  },
  {
    title: "Node.js",
    img: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/nodejs/nodejs-original-wordmark.svg",
  },
  {
    title: "Redis",
    img: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/redis/redis-plain-wordmark.svg",
  },
  {
    title: "Firebase",
    img: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/firebase/firebase-plain.svg",
  },
  {
    title: "Socket.io",
    img: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/socketio/socketio-original.svg",
  },
  // {
  //   title: "Typescript",
  //   img: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/typescript/typescript-plain.svg",
  // },
  // {
  //   title: "Jest",
  //   img: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/jest/jest-plain.svg",
  // },
  // {
  //   title: "Webpack",
  //   img: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/webpack/webpack-plain.svg",
  // },
  // {
  //   title: "Docker",
  //   img: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/docker/docker-plain.svg",
  // },
  // {
  //   title: "Kubernetes",
  //   img: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/kubernetes/kubernetes-plain.svg",
  // },
  {
    title: "Heroku",
    img: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/heroku/heroku-plain-wordmark.svg",
  },
  // {
  //   title: "Three.js",
  //   img: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/threejs/threejs-original-wordmark.svg",
  // },
  {
    title: "Illustrator",
    img: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/illustrator/illustrator-plain.svg",
  },
  {
    title: "Photoshop",
    img: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/photoshop/photoshop-plain.svg",
  },
  {
    title: "Figma",
    img: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/figma/figma-original.svg",
  },
  {
    title: "npm",
    img: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/npm/npm-original-wordmark.svg",
  },
  // {
  //   title: "",
  //   img: "",
  // },
];
