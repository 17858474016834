// Copyright 2022 mitchelleglon
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
import React from "react";
import Skills from "../components/Skills";
import Footer from "../components/Footer";
import logo from "../assets/ME-1.png";

function AboutPage() {
  return (
    <div className="about-container">
      <img src={logo} alt="logo" width="200px" />
      <p className="about-intro">
        Welcome! over the past few months I have been creating a portfolio of
        projects to showcase my skills and knowledge in web development. Having
        spent 10 years as a Maritime Electro-Technical Officer, I bring a wealth
        of technical ability and I am looking forward to starting a new career
        in web development.
      </p>
      <p>Please do not hesitate to get in touch:</p>
      <a href="mailto:mitcheglon@hotmail.co.uk" className="about-email">
        mitcheglon@hotmail.co.uk
      </a>

      {/* <Skills /> */}
    </div>
  );
}

export default AboutPage;
